.full-page {
    width: 100%;
    min-height: 100vh;
    background-color: rgb(0 97 155);
}

.products-pricing {
    margin: auto;
    margin-top:80px;
    color: rgb(120 211 177);
}

    .products-pricing .row {
        margin-top: 80px;
    }

    .products-pricing button {
        background-color: rgb(120 211 177);
        margin-bottom: 80px;
    }

@media (min-width: 992px) {
    .products-pricing {
        width: 992px;
    }
}

@media (max-width: 991px) {
    .products-pricing {
        width: 90vw;
    }
}
