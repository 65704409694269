.about-alternating-list {
    width: 80%;
    padding-top: 40px;
    margin: auto;
}

    .about-alternating-list .section-divider {
        border-bottom: 1px solid #00000030;
        width:100%;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .about-alternating-list .col-lg-8 {
    }
