.about-list-small {
    width: 80%;
    margin: auto;
    line-height: 200%;
    margin-top: -300px;
}

    .about-list-small .bi {
        color: rgb(120 211 177);
        font-size: 64px;
        font-weight: bolder;
        margin-bottom: 10px;
    }

    .about-list-small .list-item {
        background-color: white !important;
        border-radius: 50px;
        width: 90%;
        padding: 20px;
        border: 3px solid #888888;
        box-shadow: 0px 5px 5px #888888;
    }

    .about-list-small h3 {
        margin-bottom: 20px;
        line-height: 100%;
        color: rgb(120 211 177);
    }

    .about-list-small .row .col-4 {
        padding: 0px 88px;
    }

    .about-list-small .row .col-12 {
        margin-top: 80px;
    }

    .about-list-small .section-divider {
        border-bottom: 1px solid #00000030;
        width:100%;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .about-list-small img {
        width: 900px;
    }

    .about-list-small button {
        height: 50px;
        border-radius: 5px;
        width: 150px !important;
        margin: 20px auto !important;
    }

    .about-list-small .description {
        padding: 20px 50px;
    }

    @media (max-width: 991px) {
        .about-list-small {
            padding-top: 0px;
            margin-top: 20px;
        }

        .about-list-small img {
            width: 100%;
        }
    }

