$border-radius: 15px;
@import '../node_modules/bootstrap/scss/bootstrap.scss';

.hr-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: .5;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }
    &:after {
      content: attr(data-content);
      position: relative;
      display: inline-block;
      color: black;
      padding: 0 .5em;
      line-height: 1.5em;
      background-color: #fcfcfa;
    }
  }