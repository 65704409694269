.welcome-full {
    background-image: url('../../images/cloud.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vw;
    max-height: 800px;
    margin-top: 75px;
    color: white;
    text-align: center;
}

    .welcome-full .overlay {
        background-color: #000000d0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        border: 0;
    }

    .welcome-full .section-divider {
        border-bottom: 2px solid white;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .welcome-full button {
        height: 50px;
        border-radius: 5px;
        background-color: rgb(120 211 177);
    }

    @media (min-width: 992px) {
        .welcome-full .overlay .content {
            width: 600px;
        }

        .welcome-full .overlay {
            justify-content: start !important;
            padding-top: 100px;
        }
    }
    
    @media (max-width: 991px) {
        .welcome-full .overlay .content {
            width: 90vw;
        }
    }
