.App-header {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: end;
    background-color: rgb(120 211 177);
    top: 0;
    left:0;
    position: fixed !important;
}

    .App-header h3 {
        margin-left: 20%;
    }

    @media (max-width: 991px) {
        .App-header h3 {
            margin-left: 5%;
            margin-right: 22%;
        }
    }

    .App-header a, .App-header a:hover {
        text-decoration: none;
    }

    .App-header li a {
        font-size: 20px;
    }

.navbar-nav {
    margin: auto !important;
}

.hamburger {
    position: absolute !important;
    right: 10px;
    height: 70px;
}

    .hamburger button {
        width: 70px;
    }